



































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { OrderBumpPayload } from '@/sparkmembers/views/ProductSetup/components/OrderBumpConfig/types';
import GeneralHelper from '@/shared/helpers/general';
import { Money } from 'v-money';
import MInput from '@/components/MInput.vue';
import MXTextArea from '@/shared/components/MXTextArea.vue';

@Component({
  components: {
    MButton: () => import('@/shared/components/MButton.vue'),
    OrderBumpSelectModal: () => import('./OrderBumpSelectModal.vue'),
    MInput,
    MXTextArea,
    Money,
  },
})
export default class OrderBumpSelect extends Vue {
  selectedOrderBumps: Array<OrderBumpPayload> = [];

  @Prop({ default: false }) disabled: boolean;
  @Prop() preSelectedOrderBumps: Array<OrderBumpPayload>;

  mounted() {
    if (this.preSelectedOrderBumps) this.setSelectedOrderBumps(this.preSelectedOrderBumps);
  }

  get hasSelectedOrderBumps() {
    return Boolean(this.selectedOrderBumps.length);
  }

  openSelectOfferModal() {
    this.$bvModal.show('order-bump-select-modal');
  }

  setSelectedOrderBumps(offer) {
    this.selectedOrderBumps = offer;
    this.$emit('select', this.selectedOrderBumps);
  }

  orderBumpOrderByIndex(index) {
    const orderList = ['Primeiro', 'Segundo', 'Terceiro'];

    return orderList[index];
  }

  formatPrice(priceInCents) {
    const price = priceInCents / 100;
    return priceInCents ? GeneralHelper.currency(price) : 'R$ 0,00';
  }

  @Watch('preSelectedOrderBumps')
  onChangePreSelectedOrderBumps(orderBumps) {
    this.setSelectedOrderBumps(orderBumps);
  }
}
